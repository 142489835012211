.site-footer{
	&.style-1{
		position: relative;
		color: rgba(255,255,255,0.7);
		
		h1, h2, h3, h4, h5, h6{
			color: #fff;
			a{
				color: #fff;
			}
		}
		
		.widget{
			&.widget_about{
				margin-right: 20px;
				max-width: 650px;
				
				p {
					font-weight: 400;
					margin-bottom: 25px;
					line-height: 1.7;
				}
			}
			&.recent-posts-entry{
				margin-right: 30px;
				
				@include respond('phone-land'){
					margin-right: 0;					
				}
				.widget-post-bx{
					.widget-post{
						margin-bottom: 20px;
						
						.dz-info{
							padding-left: 0;
							
							.post-date{
								color: var(--primary);
								font-weight: 600;
								font-size: 14px;
							}
							h6 a {
								font-family: $font-family-base;
								font-weight: 500;
							}
						}
						.dz-meta ul li {
							color: var(--title);
						}
						.title a {
							color: white;
							padding-right: 30px;
							
							&:hover{
								color: var(--primary);
							}
						}
					}
				}
			}
		}
		
		.bg-shape1{
			position: absolute;
			right: 4%;
			bottom: 20%;
			-webkit-animation: aniRotate 10s infinite linear;
			animation: aniRotate 10s infinite linear;
			z-index: 666;
			width: 65px;
		}
		.footer-top{
			position: relative;
			display: flex;
			padding: 100px 0 45px;
			z-index: 1;
			background-color: #221646;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			background-blend-mode: luminosity;
			
			&:before{
				content: "";
				position: absolute;
				opacity: 0.95;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: #000;
				z-index: -1;
			}
			
			@include respond('tab-port'){
				padding: 50px 0 15px;
			}
		}

		.footer-bottom{
			position: relative;
			padding: 1.5rem 0;
			background-color: $primary !important;
			
			.copyright-text {
				opacity: 0.8;
				color: #000;
				
				a{
					text-decoration: underline;
					font-weight: 500;
					color: #000;
				}
			}
		}
	}
}