// text size
.font-12 {
    font-size: 12px;
}
.font-13 {
    font-size: 13px;
}
.font-14 {
    font-size: 14px;
}
.font-15 {
    font-size: 15px;
}
.font-16 {
    font-size: 16px;
}
.font-18 {
    font-size: 18px;
}
.font-20 {
    font-size: 20px;
}
.font-22 {
    font-size: 22px;
}
.font-24 {
    font-size: 24px;
}
.font-26 {
    font-size: 26px;
}

// Font Size
$value: 20;
$x: 5;
$y: px;
@for $i from 0 through $value{
    .font-#{$i*$x}{
        font-size: ($x*$i) + $y !important;
    }
}

// Font Weight
$value: 9;
$x: 100;
@for $i from 0 through $value{
    .font-w#{$i*$x}{
        font-weight: ($x*$i) + 1!important;
    }
}


// Padding Margin
$value: 20;
$x: 5;
$y: px;
@for $i from 0 through $value{
    // Padding Value
    .p-a#{$i*$x}{
        padding: ($x*$i) + $y;
    }
    .p-l#{$i*$x}{
        padding-left: ($x*$i) + $y;
    }
    .p-r#{$i*$x}{
        padding-right: ($x*$i) + $y;
    }
    .p-t#{$i*$x}{
        padding-top: ($x*$i) + $y;
    }
    .p-b#{$i*$x}{
        padding-bottom: ($x*$i) + $y;
    }
    .p-lr#{$i*$x}{
        padding-left: ($x*$i) + $y;
        padding-right: ($x*$i) + $y;
    }
    .p-tb#{$i*$x}{
        padding-bottom: ($x*$i) + $y;
        padding-top: ($x*$i) + $y;
    }
    
    // Margin Value
    .m-a#{$i*$x}{
        margin: ($x*$i) + $y;
    }
    .m-l#{$i*$x}{
        margin-left: ($x*$i) + $y;
    }
    .m-r#{$i*$x}{
        margin-right: ($x*$i) + $y;
    }
    .m-t#{$i*$x}{
        margin-top: ($x*$i) + $y;
    }
    .m-b#{$i*$x}{
        margin-bottom: ($x*$i) + $y;
    }
    .m-lr#{$i*$x}{
        margin-left: ($x*$i) + $y;
        margin-right: ($x*$i) + px;
    }
    .m-tb#{$i*$x}{
        margin-bottom: ($x*$i) + $y;
        margin-top: ($x*$i) + $y;
    }
}
@media only screen and (max-width: 1200px) {
    @for $i from 0 through $value{
        // Margin Value
        .m-lg-t#{$i*$x}{
            margin-top: ($x*$i) + px;
        }
        .m-lg-b#{$i*$x}{
            margin-bottom: ($x*$i) + px;
        }
    }
}
@media only screen and (max-width: 991px) {
    @for $i from 0 through $value{
        // Margin Value
        .m-md-t#{$i*$x}{
            margin-top: ($x*$i) + px;
        }
        .m-md-b#{$i*$x}{
            margin-bottom: ($x*$i) + px;
        }
    }
}
@media only screen and (max-width: 767px) {
    @for $i from 0 through $value{
        // Margin Value
        .m-sm-t#{$i*$x}{
            margin-top: ($x*$i) + px;
        }
        .m-sm-b#{$i*$x}{
            margin-bottom: ($x*$i) + px;
        }
    }
}
@media only screen and (max-width: 576px) {
    @for $i from 0 through $value{
        // Margin Value
        .m-xs-t#{$i*$x}{
            margin-top: ($x*$i) + px;
        }
        .m-xs-b#{$i*$x}{
            margin-bottom: ($x*$i) + px;
        }
    }
}
.m-auto {
    margin: auto;
}

// BOX MAX-WIDTH CSS
.max-w50 {
    max-width: 50px;
}
.max-w60 {
    max-width: 60px;
}
.max-w80 {
    max-width: 80px;
}
.max-w100 {
    max-width: 100px;
}
.max-w200 {
    max-width: 200px;
}
.max-w300 {
    max-width: 300px;
}
.max-w400 {
    max-width: 400px;
}
.max-w500 {
    max-width: 500px;
}
.max-w600 {
    max-width: 600px;
}
.max-w700 {
    max-width: 700px;
}
.max-w800 {
    max-width: 800px;
}
.max-w900 {
    max-width: 900px;
}
.max-w1000 {
    max-width: 1000px;
}

// BACKGROUND IMAGE
.sticky-top{
	top:120px;
}
.img-cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
section{
    position: relative;
	z-index: 1;
}
.section-head{
	margin-bottom:40px;
	
	@include respond('phone-land'){
		margin-bottom: 30px;
	}
	
	h1, h2, h3, h4, h5, h6{
		font-family: var(--font-family-title);
	}
	
	.sub-title{
		font-weight: 500;
		letter-spacing: 2px;
		font-size: 16px;
	}
	h2.title{
		font-size: 50px;
		font-weight: 600;
		line-height: 1.2;
		margin-bottom: 10px;
		color: $secondary;
		
		@include respond('tab-land'){
			font-size: 42px;
		}
		@include respond('tab-port'){
			font-size: 36px;
		}
		@include respond('phone'){
			font-size: 28px;
		}
	}
	h3.title{
		font-size: 50px;
		font-weight:600;
		line-height: 1.2;
		
		@include respond('laptop'){
			font-size: 42px;
		}
		@include respond ('tab-land'){
			font-size: 36px;
		}
		@include respond ('phone-land'){
			font-size: 28px;
		}
	}
	h4.title{
		font-size: 35px;
		font-weight:600;
		line-height: 1.2;
		margin-bottom: 5px;
		
		@include respond('laptop'){
			font-size: 30px;
		}
		@include respond ('tab-land'){
			font-size: 26px;
		}
		@include respond ('phone-land'){
			font-size: 22px;
		}
	}
	p{
		font-size: 18px;
		line-height: 1.5;
		font-weight: 400;
		
		@include respond('phone-land'){
			font-size: 16px;
		}		
	}
	&.text-center{
		max-width: 850px;
		margin-left: auto;
		margin-right: auto;
		
		p{
			max-width:585px;
			margin-left:auto;
			margin-right:auto;
			color: #000;
		}


	}
	&.m-b30{
		margin-bottom:30px;
	}
}

.row.spno,
.spno{
	margin-left:0;
	margin-right:0;
	
	[class*="col"],
	[class*="col"]{
		padding-left:0;
		padding-right:0;
	}
}
.row.sp4,
.sp4{
	margin-left:-4px;
	margin-right:-4px;
	
	[class*="col"],
	[class*="col"]{
		padding-left:4px;
		padding-right:4px;
	}
}
.row.sp15,
.sp15{
	margin-left:-7px;
	margin-right:-7px;
	
	[class*="col"],
	[class*="col"]{
		padding-left:7px;
		padding-right:7px;
	}
}
.row.sp10,
.sp10{
	margin-left:-10px;
	margin-right:-10px;
	[class*="col"],
	[class*="col"]{
		padding-left:10px;
		padding-right:10px;
	}
}
.row.sp60,
.sp60{
	margin-left:-30px;
	margin-right:-30px;
	[class*="col"],
	[class*="col"]{
		padding-left:30px;
		padding-right:30px;
	}
}

// rounded
.rounded-xl{
	border-radius: 50% !important;
	overflow: hidden;
}
.rounded-lg{
	border-radius: 20px !important;
	overflow: hidden;
}
.rounded-md{
	border-radius: $border-radius-base !important;
	overflow: hidden;
}
.rounded-sm{
	border-radius: 4px !important;
	overflow: hidden;
}


// text color
.text-maroon{
	color: $maroon;
}
.text-orange{
	color: $orange;
}
.text-yellow{
	color: $yellow;
}
.text-skyblue{
	color: $green;
}
.text-red{
	color: $red;
}
.text-green{
	color: $green;
}
.text-blue{
	color: $blue;
}

// bg color
.bg-maroon{
	background-color: $maroon;
}
.bg-orange{
	background-color: $orange;
}
.bg-yellow{
	background-color: $yellow;
}
.bg-skyblue{
	background-color: $skyblue;
}
.bg-red{
	background-color: $red;
}
.bg-green{
	background-color: $green;
}
.bg-blue{
	background-color: $blue;
}

// Scale
.scale8{
	transform:scale(1.85);
	-moz-transform:scale(1.85);
	-webkit-transform:scale(1.85);
	-ms-transform:scale(1.85);
	-o-transform:scale(1.85);
}
.scale7{
	transform:scale(1.7);
	-moz-transform:scale(1.7);
	-webkit-transform:scale(1.7);
	-ms-transform:scale(1.7);
	-o-transform:scale(1.7);
}
.scale5{
	@include transformScaleMD;
}
.scale3{
	transform:scale(1.3);
	-moz-transform:scale(1.3);
	-webkit-transform:scale(1.3);
	-ms-transform:scale(1.3);
	-o-transform:scale(1.3);
}
.scale2{
	@include transformScaleSM;
}
.scale08{
	@include transformScaleXS;
}
.scale05{
	@include transformScaleXS1;
}
.tl{
	transform-origin: left;
	-moz-transform-origin: left;
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	-o-transform-origin: left;
}
.shadow-none{
	box-shadow: unset;
}


.separator-2 {
    width: 55px;
    height: 5px;
    border-radius: 5px;

	position: relative;
	z-index: 2;
}
