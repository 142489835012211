:root{
	--header-height: 80px;
}

// Header Logo
.logo-header {
    display: table;
    float: left;
    vertical-align: middle;
    padding: 0;
    color: #EFBB20;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 215px;
    height: var(--header-height);
    position: relative;
    z-index: 9;
	
	a{
		display: table-cell;
		vertical-align: middle;
	}

	// Text Logo
	span{
		font-size: 20px;
		letter-spacing: 20px;
	}
	
	@include respond('phone'){
		width: 185px;
	}
}

// Top Bar
.top-bar {
	background-color: var(--primary);
    color: #212529;
    padding: 8px 0;
    position: relative;
	
	.dz-topbar-inner{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-left:-15px;
		margin-right:-15px;
	}
	.dz-topbar-left{
		padding-left: 15px;
	}
	.dz-topbar-right{
		display: flex;
		align-items: center;
		padding-right: 15px;
		ul{
			margin-left:15px;
		}
	}
	.top-info{
		li{
			display: inline-flex;
			padding-right: 15px;
		}
		p{
			color: $white;
			margin-bottom: 0;
			font-size: 0.8125rem;
			font-weight: 500;
			&.label{
				color: #BCFAF2;
				margin-right: 4px;
				font-weight: 400;
			}
		}
	}

	@include respond('tab-port'){
		display:none;
	}	
}


// Site Header
.site-header {
	position:relative;
	z-index:9999;

	.main-bar{
		background: #fff;
		width: 100%;
		position: relative;
		box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
		padding: 12px 0;
		
		@include respond('phone'){
			padding: 0;		
		}
	}
	.container{
		position: relative;
	}
	.extra-nav {
		float: right;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 9;
		height: var(--header-height);
		padding-left: 30px;
		
		@include respond('tab-land'){
			padding-left: 20px;
		}
		@include respond('phone-land'){
			display: none;
		}
		
		.extra-cell{
			display: flex;
			align-items: center;
			& > * {
				margin-left:10px;
			} 
		}
		#quik-search-btn{
			color: #fff;
			cursor: pointer;
			margin-right: 0px;
			border: 0;
			width: 45px;
			height: 45px;
			background: var(--secondary);
			line-height: 45px;
			text-align: center;
			font-size: 18px;
			border-radius: var(--border-radius-base);
		}
		.btn{
			@include respond('phone'){
				padding-left:15px;
				padding-right:15px;
			}
		}
		
		ul{
			li{
				display:inline-block;
				.icon{
					padding: 0;
					background: transparent;
					min-width: 40px;
					text-align: center;
					height: 40px;
					line-height: 40px;
					border-radius: 4px;
				}
			}
		}
		.extra-svg{
			display: flex;
			margin: 0 -5px;
			.tp-user{
				height:45px;
				width: 45px;
				border: 1px solid var(--primary);
				line-height: 40px;
				text-align: center;
				border-radius: $border-radius-base;
				margin: 0 5px;
			}
		}
	}
}

// Navbar Toggler
.navbar-toggler {
    border: 0 solid #efbb20;
    font-size: 16px;
    line-height: 24px;
    margin: 40px 0 39px 15px;
    padding: 0;
	float: right;
	outline: none !important;
	
	@include respond('tab-port'){
		margin: 18px 0px 16px 10px;
	}
}

// Navicon
.navicon{
	width: 45px;
    height: 45px;
	background: var(--primary);
    box-shadow: 0 5px 15px -10px var(--primary);
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	border-radius:var(--border-radius-base);
	
	span{
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		border-radius: 1px;
		opacity: 1;
		background: #fff;
		left:10px;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
		
		&:nth-child(1) {
			top: 13px;
			width: 22px;
		}
		&:nth-child(2) {
			top: 22px;
			width: 25px;
		}
		&:nth-child(3) {
			top: 32px;
			width: 20px;
		}
		
	}
	
	&.open span:nth-child(1) {
	  top: 23px;
	  -webkit-transform: rotate(135deg);
	  -moz-transform: rotate(135deg);
	  -o-transform: rotate(135deg);
	  transform: rotate(135deg);
	}
	&.open span:nth-child(2) {
		opacity: 0;
		left: -60px;
	}
	&.open span:nth-child(3) {
		top: 23px;
		width: 23px;
		-webkit-transform: rotate(-135deg);
		-moz-transform: rotate(-135deg);
		-o-transform: rotate(-135deg);
		transform: rotate(-135deg);
	}
}
// Header-tb
.header-tb .navicon span{
	background:#fff;
}

// Header Nav
.header-nav {
    padding: 0;
	
	@include respond('tab-port'){
		overflow-y: scroll;
		position: fixed;
		width: 60px;
		left:-280px;
		height:100vh !important;
		@include transitionMedium;
		top:0;
		background-color: $white;
		margin:0;
		z-index:99;
	}
	&.show {
		@include respond('tab-port'){
			left: -1px;
			@include transitionSlow;
			margin:0;
			width: 280px;
		}
	}
	.logo-header{
		display: none;
		@include respond('tab-port'){
			display: block;
			padding: 20px 20px;
			float: none;
			height: auto;
			width: 240px;
			
			img {
				height: 50px;
			}
			
			.logo-dark{
				display: block !important;
			}
		}
	}
	.mo-footer{
		display: none;
		padding: 30px 20px;
		text-align: center;
		
		
		@include respond('tab-port'){
			display: flex;
			padding: 30px 20px;
			text-align: center;
			gap: 15px;
			.dz-theme-mode{
				position: absolute;
				right: 15px;
				top: 28px;
				z-index: 14;
				border: 1px solid #cfdbed;
				border: 1px solid var(--border-color);
			}
		}
	}
	.nav{
		float: right;
		padding: 0;
		font-size: 0;
			
		&>li {
			margin: 0px;
			font-weight: 400;
			position: relative;
			
			&.has-mega-menu {
				position: inherit;
			}
			@include respond('tab-port'){	
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);	
				width: 100%;	
			}
			&.menu-item-has-children,					   
			&.sub-menu-down{
				&>a {
					&:after{
						content:"\f078";
						font-size: 9px;
						margin-left: 5px;
						margin-top: 0;
						vertical-align: middle;
						font-weight: 900;
						font-family: "Font Awesome 5 Free";
							
						@include respond('tab-port'){	
							content: "\f054";
							background-color: var(--rgba-primary-2);
							color: var(--primary);
							height: 30px;
							line-height: 30px;
							right: 0;
							text-align: center;
							width: 30px;
							z-index: 3;
							float: right;
							font-size: 14px;
							border-radius: 4px;
							margin-top: -7px;
						}
						&:before {
							@include respond('tab-port'){
								content: "\f078";
							}
						}
					}
				}
				@include respond('tab-port'){
					&.open{
						&>a {
							&:after{
								content:"\f078";
							    color: #fff;
							    background: var(--primary);
							}
						}
					}
				}
			}
			&>a {
				color: var(--body-color);
				font-size: 16px;
				padding: 32px 20px;
				font-family:var(--font-family-title);
				cursor: pointer;
				font-weight: 400;
				display: inline-block;
				position: relative;
				border-width: 0 0 0 1px;
				text-transform: capitalize;
				line-height: 1;
				
				@include respond('tab-land'){
					padding-left:10px;
					padding-right:10px;
					font-size: 15px;
				}
				@include respond('tab-port'){	
					padding: 15px 0;
					display: block;
					color: $headings-color;
				}
				span{
					display:inline-block;
				}

				&.active{	
					color:var(--primary);
				}
			}
			.mega-menu,
			.sub-menu {
				background-color: #fff;
				display: block;
				left: 0;
				list-style: none;
				opacity: 0;
				padding: 15px 0;
				position: absolute;
				visibility: hidden;
				width: 220px;
				z-index: 10;
				margin-top: 20px;
				text-align: left;
				border-radius:0 var(--border-radius-base) var(--border-radius-base) var(--border-radius-base);
				@include transitionSlow;
				box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
				
				@include respond('tab-port'){	
					display: none;
					position: static;
					visibility: visible;
					width: auto;
					background: transparent;
					box-shadow: none;
					margin: 0;
					border-top:1px solid #eee;
					padding:10px 0;
					@include transitionNone;
					
					& > li > a i{
						color: #fff!important;
						height: 30px;
						line-height: 30px;
						right: 0;
						text-align: center;
						width: 30px;
						z-index: 3;
						font-size: 14px!important;
						margin: 0!important;	
						background: #aaa;						
					}
				}
				.menu-item-has-children{ 
					& > a:before{
						content: "\f054";
						font-weight: 900;
						font-family: "Font Awesome 5 Free";
						float: right;
						font-size: 10px;
						margin-top: 2px;
					}
					@include respond('tab-port'){	
						&.open > a:before{
							content: "\f054";
						}
					}
				}
				
				@include respond('tab-port'){	
					display: none;
					position: static;
					visibility: visible;
					width: auto;
					background: transparent;
					box-shadow: none;
					margin: 0;
					border-top:1px solid #eee;
					padding: 0 0 5px;
					@include transitionNone;
					opacity:1;
					
					& > li > a i{
						display:none;
					}
					.menu-item-has-children > a:before,
					& > li > a i{
						color: #fff !important;
						font-size: 14px;
					    content: "\f054";
						height: 30px;
						line-height: 30px;
						right: 0;
						text-align: center;
						width: 30px;
						z-index: 3;
						margin: 0!important;	
						background:var(--primary);
						border-radius: var(--border-radius-base);
					}
					.menu-item-has-children.open > a:before{
						content: "\f078";
					    background: #333;
					}
				}
				li {
					position: relative;
					
					a{
						color: $headings-color;
						display: block;
						font-size: 15px;
						padding:10px 20px;
						line-height: 1.3;
						text-transform: capitalize;
						position:relative;
						transition: all 0.15s linear;
						-webkit-transition: all 0.15s linear;
						-moz-transition: all 0.15s linear;
						-o-transition: all 0.15s linear;
						font-weight: 500;
						
						@include respond('tab-port'){
							padding: 4px 0 4px 15px;
							display: block;
							line-height: 30px;
							position: relative;
							border: 1px solid #eee;
							border-width: 0 0 1px 0px;
							&:after{
								content: "";
								font-family: "Font Awesome 5 Free";
								width: auto;
								height: auto;
								background: 0 0!important;
								top: 3px;
								color: #262F5A;
								font-size: 12px;
								position: absolute;
								left: 0;
								font-weight: 700;
							}
							&:last-child{
								border-bottom: 0;
							}
						}
					}
					&:hover > a{
						color:var(--primary);
						
						@include respond('tab-port'){
							padding: 4px 0 4px 15px;
							background:transparent;
						}
						&:after {
							width: 10px;
						}
					}
				}
				li>.sub-menu {
					left: 220px;
					@include transitionMedium;
					
					@include respond('tab-port'){
						display:none;
					}
				}
				@include respond('tab-port'){
					.mega-menu,
					.sub-menu{
						border-top:0;
						padding-left: 20px;
						li  a:after{
							width: 5px;
							content: none;
							height: 5px;
							background: #262F5A !important;
							border-radius: 4px;
							top: 13px;
						}
					}
				}
			}
			.sub-menu{
				li > a > i {
					color: inherit;
					display: block;
					float: right;
					font-size: 18px;
					opacity: 1;
					margin-top: 0px;	
					@include respond('tab-port'){
						display:none;
					}
				}				
			}
			.mega-menu {
				display: flex;
				right: 0px;
				width: 100%;
				z-index: 9;
				padding: 0;
				& > li {
					display: block;
					padding: 15px 0 15px;
					position: relative;
					vertical-align: top;
					width: 25%;
					@include respond('tab-port'){
						width: 100%;
						padding:0;
					}
					&>a {
						color: $dark;
						display: block;
						padding: 0 20px;
						font-size: 16px;
						font-weight: 600;
						border-bottom: 1px solid rgba(0,0,0,0.1);
						padding-bottom: 15px;
						&>i{
							font-size: 14px;
							margin-right: 5px;
							text-align: center;
							width: 15px;
						}
						@include respond('tab-port'){
							display:none;
						}
					}
					&:after {
						content: "";
						background-color: rgba(0, 0, 0, 0.1);
						position: absolute;
						right: 0px;
						top: 0px;
						display: block;
						width: 1px;
						height: 100%;
						@include respond('tab-port'){
							content:none;
						}
					}
					&:last-child:after {
						display: none;
					}
					&:hover > a{
						background-color: transparent;
					}
					li i{
						font-size: 14px;
						margin-right: 5px;
						text-align: center;
						width: 15px;
					}
					
				}
				@include respond('tab-port'){	
					display: none;
					padding:10px 0;
				}
			}
			&.active{	
				&>a{
					color:var(--primary);
				}
			}
			&:hover{
				&>a{	
					color:var(--primary);
				}				 
				&>.mega-menu,
				&>.sub-menu{
					opacity: 1;
					visibility: visible;
					margin-top: 0;
					@include transitionSlow;
					@include respond('tab-port'){
						@include transitionNone;
					}
					li:hover > .sub-menu{
						opacity: 1;
						visibility: visible;
						margin-top: 0;
						top: -1px;
					}
				}
			}
			&.open{
				.mega-menu,
				.sub-menu{
					display:block;
					opacity:1;
					@include transitionNone;
					
				}
				.open{
					&.menu-item-has-children > a:before{
					
					}
					.mega-menu,
					.sub-menu{
						display:block;
						opacity:1;
						@include transitionNone;
					}
				}
			}
		}
		@include respond('laptop'){
			&>li:nth-last-child(4),
			&>li:nth-last-child(3),
			&>li:nth-last-child(2),
			&>li:last-child{
				.sub-menu .sub-menu{
					left:-220px;
				}
			}
		}
		@include respond('tab-port'){
			float: none;
			padding: 0 20px;	
		}
	}
	.sidebar-footer{
		display: none;
		@include respond('tab-port'){
			display:block;
			padding-top:30px;
			padding-bottom:20px;
			margin-top: auto;
			width: 100%;
			text-align: center;
		}
		.dz-login-register{
			text-align:center;
			padding-bottom:10px;
		}
	}
	.dz-social-icon{
		text-align:center;
		display: none;
		li{
			display:inline-block;
			margin: 0 2px;
		}
		a{
			width: 40px;
			height: 40px;
			padding: 0;
			border: 1px solid rgba(0,0,0,0.1);
			line-height: 38px;
			text-align: center;
			border-radius: var(--border-radius-base);
		}
		@include respond('tab-port'){
			display:block;
			padding-top:30px;
			padding-bottom:20px;
		}
	}
}


// Header Transparent{
.header-transparent{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	
	.main-bar{
		background: transparent;
		box-shadow: none;
	}
	.container-fluid{
		padding-left:80px;
		padding-right:80px;
		@include respond('tab-land'){
			padding-left:15px;
			padding-right:15px;
		}
	}
	
	.navbar-toggler{
		span{
			background:$white;
		}
	}
	.is-fixed{
		.nav{
			&>li {
				&>a {
					color: $dark;
				}
			}
		}
		.btn.text-white:not(:hover){
			color: $dark !important;
		}
	}
	
	// Top Bar
	.top-bar{
		border-color:$white;
		.dz-topbar-left,
		.dz-topbar-right{
			li,
			i{
				color: $white;
			}
		}
	}
	& + .page-content{
		.dz-bnr-inr{
			.dz-bnr-inr-entry{
				padding-top: 85px;
			}
		}
	}

	.nav{
		& > li{
			& > a{
				@include respond('tab-port-min'){
					color: #fff;
				}
			}
		}
	}
	.logo-header{
		.logo-light{
			display: table-cell;
		}
		.logo-dark{
			display: none;
		}
	}
	.is-fixed{
		.logo-header{
			.logo-light{
				display: none;
			}
			.logo-dark{
				display: table-cell;
			}
		}
	}


}

// Is Fixed
.is-fixed{
	.main-bar {
		position: fixed;
		top: 0;
		left: 0;
		box-shadow: 0 0 50px 0 rgba(0,0,0,.1);
		background: var(--card-bg);
		transition: all 0.5s;
		padding: 0;
	}
	.logo-header img {
		padding: 10px 0;
	}
}

// Mobile Nav
@include respond('tab-port'){
	.mo-left{
		.header-nav{
			overflow-y: auto;
			position: fixed;
			width: 280px;
			left:-280px;
			height:100vh !important;
			transition:all 0.5s;
			-webkit-transition:all 0.5s;
			-moz-transition:all 0.5s;
			-o-transition:all 0.5s;
			top:0;
			background-color: var(--card-bg);
			margin:0;
			z-index:99;
			&.nav-dark{
				background-color:#202020;
			}
			&.show {
				left: -1px;
				transition:all 0.8s;
				-webkit-transition:all 0.8s;
				-moz-transition:all 0.8s;
				-o-transition:all 0.8s;
				margin:0;
				width: 280px;
			}
			.logo-header {
				display: block;
				float: none;
				height: auto!important;
				max-width: 100%;
				padding: 30px 20px;
				width: 100%!important;
				
				img{
					padding: 0;
					height: auto;
					max-width: unset;
					vertical-align: middle;
				}
			}
			li{
				&.open{
					a{
						position:relative;
					}
				}
			}
		}	
		.navbar-toggler{
			&.open{
				z-index: 99;
				&:after {
					content: "";
					left: 0;
					position: fixed;
					right: -20px;
					top: -63px;
					width: 100%;
					z-index: -1;
					margin: 0 0px 0px 20px;
					transition: all 0.5s;
					transform: scale(100);
					-o-transform: scale(100);
					-moz-transform: scale(100);
					-webkit-transform: scale(100);
					transform-origin: top right;
					background-color: rgba(0, 0, 0, 0.6);
					box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
					-webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
				}
				span{
					background:#fff;
				}
			}
		}
	}
}
.text-black{
	.nav{
		&>li {
			&>a {
				color: $dark;
			}
		}
	}
	.navbar-toggler{
		span{
			background:$dark;
		}
	}
}

// Header is-fixed Animation Start
.main-bar{
	-webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    -khtml-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;	
}
.is-fixed .main-bar{
	-webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
	-khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
	-moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
	-ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
	-o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
	animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
}
@-moz-keyframes headerSlideDown {
	0% {
		margin-top: -150px;
	}
	100% {
		margin-top: 0;
	}
}
@-ms-keyframes headerSlideDown {
	0% {
		margin-top: -150px;
	}
	100% {
		margin-top: 0;
	}
}
@-webkit-keyframes headerSlideDown {
	0% {
		margin-top: -150px;
	}
	100% {
		margin-top: 0;
	}
}
@keyframes headerSlideDown {
	0% {
		margin-top: -150px;
	}
	100% {
		margin-top: 0;
	}
}
// Header is-fixed Animation End